import { ColDef } from "ag-grid-community";
import React, { useEffect, useMemo, useState } from "react";
import { Card, CardBody, CardTitle, UncontrolledTooltip } from "reactstrap";
import { IMember, IMinUser, IPNSProps, IUser } from "../../../../../types/global.types";
import {
  Checkbox,
  HSpace,
  McDropdown,
  McDropdownSingleUser,
  McInput,
  McTypeahead,
  SimpleTags,
  VSpace,
} from "../../../components/";
import { UserCellRenderer } from "../../../components/ConnectedIssues/ConnectedIssues";
import TypeAheadUser from "../../../components/TypeAheadUser/TypeAheadUser";
import ViewEditTable from "../../../components/ViewEditTable/ViewEditTable";
import { ToastMessagesEnums } from "../../../frontendConstants";
import { setShowSuccessMessageThunk } from "../../../global/globalSlice";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import ChecklistRemove from "./admin/ChecklistRemove";
import ChecklistTypes from "./admin/ChecklistTypes";
import StampConfiguration from "./admin/StampConfiguration";
import { useGetUniqueChecklistTagsQuery } from "./checklistRTK";
import { updateChecklistThunk } from "./checklistSlice";
import { ChecklistStatusEnum, CHECKLISTSTATUSES, TEMPLATESTATUSES } from "./constants";

const ChecklistAdmin: React.FC = () => {
  const dispatch = useAppDispatch();
  const membersInRedux: IMember[] = useAppSelector((state) => state.adminReducer.project.members);
  const project = useAppSelector((state) => state.adminReducer.project);
  const checklist = useAppSelector((state) => state.checklistReducer.checklist);
  const pnsItemsInRedux = useAppSelector((state) => state.adminReducer.project.pns);
  const [pnsItems, setPnsItems] =
    useState<{ comboLabel: string; _id: string; parentId: string; props: IPNSProps }[]>(null);

  const [removed, setRemoved] = useState<boolean>(false);

  const updateChecklist = async (prop: string, oldVal, newVal) => {
    dispatch(updateChecklistThunk(prop, newVal, oldVal));
    dispatch(setShowSuccessMessageThunk(ToastMessagesEnums.SETTINGS_UPDATED));
  };

  useEffect(() => {
    setRemoved(checklist.status === ChecklistStatusEnum.REMOVED);
  }, [checklist.status]);

  useEffect(() => {
    setPnsItems(
      pnsItemsInRedux?.map((pns) => ({
        ...pns,
        comboLabel: `${pns.props.pnsId} - ${pns.props.title}`,
      })),
    );
  }, []);

  const { data: tags = [] } = useGetUniqueChecklistTagsQuery({ projectId: project._id });

  // Name must always be a string or crash in ag-grid cell editor when autocomplete is selecting empty/null user
  const emptyDocManager = { name: "", _id: "" } as IUser;
  const isNullOrUndefined = (value) => value == null || value === "";

  return (
    <div id="a" className="h-100 w-100 p-2 overflow-auto">
      {checklist.isTemplate === true && (
        <Card>
          <CardBody style={{ maxWidth: "35rem" }}>
            <CardTitle tag="h3">Mal instillinger</CardTitle>
            <DocumentManagers
              onSave={(roles) =>
                updateChecklist(
                  "documentManagers",
                  {
                    preparedBy: checklist.documentManagers?.preparedBy?.name ?? "",
                    checkedBy: checklist.documentManagers?.checkedBy?.name ?? "",
                    approvedBy: checklist.documentManagers?.approvedBy?.name ?? "",
                  },
                  {
                    preparedBy: isNullOrUndefined(roles.preparedBy?._id) ? roles.preparedBy : null,
                    checkedBy: isNullOrUndefined(roles.checkedBy?._id) ? roles.checkedBy : null,
                    approvedBy: isNullOrUndefined(roles.approvedBy?._id) ? roles.approvedBy : null,
                  },
                )
              }
              documentManagers={{
                preparedBy: checklist.documentManagers?.preparedBy ?? emptyDocManager,
                checkedBy: checklist.documentManagers?.checkedBy ?? emptyDocManager,
                approvedBy: checklist.documentManagers?.approvedBy ?? emptyDocManager,
              }}
              projectMembers={[
                {
                  user: emptyDocManager,
                  isPassive: false,
                  disciplin: "",
                  group: null,
                },
                ...membersInRedux,
              ]}
            />
            <VSpace />
            <div>
              <h5 className="mb-1">Admin</h5>
              <TypeAheadUser
                assignedTo={checklist.admin}
                members={membersInRedux}
                setAssignToUser={(_id) => updateChecklist("admin", checklist.admin, _id)}
                focusOnOpen={false}
                usedInForm={true}
              />
            </div>
            <VSpace />
            <div>
              <h5 className="mb-1">Kategori</h5>
              <McInput
                type="text"
                onBlur={(e) => updateChecklist("templateCategory", checklist.templateCategory, e.target.value)}
                value={checklist.templateCategory}
                disabled={removed === true}
              />
            </div>
            <VSpace />
            <div>
              <h5 className="mb-1">Revisionsnr</h5>
              <McInput
                type="text"
                onBlur={(e) => updateChecklist("revisionNr", checklist.revisionNr, e.target.value)}
                value={checklist.revisionNr}
                disabled={removed === true}
              />
            </div>
            <VSpace />
            <div>
              <h5 className="mb-1">Revisionsdato</h5>
              <McInput
                type="date"
                onBlur={(e) => updateChecklist("revisionDate", checklist.revisionDate, e.target.value)}
                value={checklist.revisionDate}
                disabled={removed === true}
              />
            </div>
          </CardBody>
        </Card>
      )}

      <VSpace />

      <Card>
        <CardBody style={{ maxWidth: "35rem" }}>
          <CardTitle tag="h3">Innstillinger</CardTitle>
          <div>
            <h5 className="mb-1">Navn</h5>
            <McInput
              required={true}
              onBlur={(e) => updateChecklist("name", checklist.name, e.target.value)}
              value={checklist.name}
              disabled={removed === true}
            />
          </div>
          <VSpace />
          {checklist.isTemplate === true && (
            <>
              <div>
                <h5 className="mb-1">Admin</h5>
                <TypeAheadUser
                  assignedTo={checklist.admin}
                  members={membersInRedux}
                  setAssignToUser={(_id) => updateChecklist("admin", checklist.admin, _id)}
                  focusOnOpen={false}
                  usedInForm={true}
                />
              </div>
              <VSpace />
            </>
          )}
          <div>
            <h5 className="mb-1">Status</h5>
            <McDropdown
              currentValue={(checklist.isTemplate === true ? TEMPLATESTATUSES : CHECKLISTSTATUSES).find(
                (s) => s.value === checklist.status,
              )}
              options={
                checklist.isTemplate === true
                  ? TEMPLATESTATUSES.filter((x) => x.value !== "REMOVED")
                  : CHECKLISTSTATUSES.filter((x) => x.value !== "REMOVED")
              }
              displayKey="label"
              onChange={(oldValue, newValue) => updateChecklist("status", oldValue?.value, newValue.value)}
              disabled={removed === true}
            />
          </div>
          <VSpace />
          <div>
            <h5 className="mb-1">Etiketter på sjekkliste</h5>
            <SimpleTags
              editable={removed === false}
              activeTags={checklist.tags || []}
              tagOptions={tags.map((t) => t._id)}
              onChange={(tags) => updateChecklist("tags", checklist.tags, tags)}
            />
          </div>
          <VSpace />
          <div>
            <h5 className="mb-1">Etiketter på spørsmål</h5>
            <div className="d-flex" style={{ minHeight: "2.4rem" }}>
              <Checkbox
                isChecked={checklist.itemTagsActive === true}
                setChecked={(e) =>
                  updateChecklist(
                    "itemTagsActive",
                    checklist.itemTagsActive,
                    checklist.itemTagsActive === true ? false : true,
                  )
                }
              />
              {checklist.itemTagsActive === true && (
                <>
                  <HSpace />
                  <SimpleTags
                    editable={removed === false}
                    activeTags={checklist.itemTags || []}
                    tagOptions={checklist.itemTags}
                    onChange={(itemTags) => {
                      const unique = [...new Set(itemTags)];
                      if (JSON.stringify(unique) !== JSON.stringify(checklist.itemTags)) {
                        updateChecklist("itemTags", checklist.itemTags, unique);
                      }
                    }}
                  />
                </>
              )}
            </div>
          </div>
          <VSpace />
          <div>
            <h5 className="mb-1">Aktiver IR på opprinnelige roller</h5>
            <Checkbox
              isChecked={checklist.includeIrOnDefaultRoles}
              setChecked={(e) =>
                updateChecklist(
                  "includeIrOnDefaultRoles",
                  checklist.includeIrOnDefaultRoles,
                  !checklist.includeIrOnDefaultRoles,
                )
              }
            />
          </div>
          <VSpace />
          <div>
            <h5 className="mb-1">Sjekklisteroller</h5>
            <ChecklistTypes
              checkTypes={checklist.checkTypes}
              editable={removed === false}
              onChange={(types) => updateChecklist("checkTypes", JSON.stringify(checklist.checkTypes), types)}
            />
          </div>
          <VSpace />
          {checklist.isTemplate === false && (
            <>
              <div>
                <h5 className="mb-1">Ansvarlig</h5>
                <McDropdownSingleUser
                  disabled={removed === true}
                  filterActive={membersInRedux.length > 10}
                  options={membersInRedux.map((member) => member.user)}
                  onChange={(user) => updateChecklist("assignedTo", checklist.assignedTo?.name, user)}
                  selected={checklist.assignedTo || { _id: "", name: "" }}
                />
              </div>
              <VSpace />
              <div>
                <h5 className="mb-1">Tidsfrist</h5>
                <McInput
                  type="date"
                  onChange={(e) =>
                    updateChecklist(
                      "dueDate",
                      checklist.dueDate,
                      e.target.value !== "" ? new Date(e.target.value) : undefined,
                    )
                  }
                  value={checklist.dueDate != null ? new Date(checklist.dueDate).toISOString() : null}
                  disabled={removed === true}
                />
              </div>
              <VSpace />
              <div>
                <h5 className="mb-1">PNS</h5>
                {pnsItems != null && (
                  <McTypeahead
                    id="pnstypeahead"
                    clearButton
                    defaultSelected={(() => {
                      return pnsItems.filter((p) => p.props.pnsId === checklist.pns && checklist.pns !== "");
                    })()}
                    labelKey="comboLabel"
                    options={pnsItems}
                    onChange={(items) => {
                      if (items[0] == null || items[0]?.props?.pnsId == null) {
                        updateChecklist("pns", checklist.pns, "");
                        return;
                      }

                      updateChecklist("pns", checklist.pns, items[0].props.pnsId);
                    }}
                    allowNew
                  />
                )}
              </div>
            </>
          )}
        </CardBody>
      </Card>

      <VSpace />

      <Card>
        <CardBody style={{ maxWidth: "35rem" }}>
          <CardTitle tag="h3">Rapportinfo</CardTitle>
          <div>
            <h5 className="mb-1">Fase i oppdraget</h5>
            <McInput
              onBlur={(e) => updateChecklist("phase", checklist.phase, e.target.value)}
              value={checklist.phase ?? ""}
              disabled={removed === true}
            />
          </div>
          <VSpace />
          <div>
            <h5 className="mb-1">Disiplin/fag</h5>
            <McInput
              onBlur={(e) => updateChecklist("discipline", checklist.discipline, e.target.value)}
              value={checklist.discipline ?? ""}
              disabled={removed === true}
            />
          </div>
          <VSpace />
          <div>
            <h5 className="mb-1">Navn/nr. kontrollert dokument/aktivitet</h5>
            <McInput
              onBlur={(e) => updateChecklist("activity", checklist.activity, e.target.value)}
              value={checklist.activity ?? ""}
              disabled={removed === true}
            />
          </div>
          <VSpace />
          <div>
            <h5 className="mb-1">Beskrivelse</h5>
            <McInput
              type="textarea"
              value={checklist.aboutText}
              onBlur={(e) => updateChecklist("aboutText", checklist.aboutText, e.target.value)}
              disabled={removed === true}
              min={3}
              dynamicHeight={true}
            />
          </div>
          <VSpace />
          <div>
            <h5 className="mb-1">Rapporttekst</h5>
            <StampConfiguration
              currentConfig={checklist.stampSettings}
              onChange={(newStampSettings) => updateChecklist("stampSettings", "", newStampSettings)}
            />
          </div>
        </CardBody>
      </Card>
      <VSpace />
      <ChecklistRemove disabled={removed === true} />
    </div>
  );
};

export default ChecklistAdmin;

export interface IDocumentManagers {
  preparedBy: IMinUser;
  checkedBy: IMinUser;
  approvedBy: IMinUser;
}

interface IDocumentManagerProps {
  documentManagers: IDocumentManagers;
  projectMembers: IMember[];
  onSave: (data: IDocumentManagers) => void;
}

const DocumentManagers: React.FC<IDocumentManagerProps> = ({ onSave, documentManagers, projectMembers }) => {
  const saveChanges = (newData) => {
    onSave({
      preparedBy: newData[0].user,
      checkedBy: newData[1].user,
      approvedBy: newData[2].user,
    });
  };

  const _columns: ColDef[] = useMemo(() => {
    return [
      {
        headerName: "Rolle",
        field: "role",
        editable: false,
      },
      {
        headerName: "Bruker",
        field: "user",
        editable: true,
        cellRenderer: (params) => <UserCellRenderer height="15px" user={params.value} />,
        cellEditor: "agRichSelectCellEditor",
        valueGetter: (params) => {
          return params.data.user ?? null;
        },
        cellEditorParams: {
          cellRenderer: (params) => <UserCellRenderer height="15px" user={params.value} />,
          formatValue: (value) => value?.name ?? "",
          values: projectMembers.map((m) => m.user),
          allowTyping: true,
          filterList: true,
        },
      },
    ];
  }, [projectMembers]);

  const _data = useMemo(() => {
    return [
      {
        role: "Utarbeidet av",
        user: documentManagers?.preparedBy,
      },
      {
        role: "Kontrollert av",
        user: documentManagers?.checkedBy,
      },
      {
        role: "Godkjent av",
        user: documentManagers?.approvedBy,
      },
    ];
  }, [documentManagers]);

  return (
    <div>
      <div className="d-flex">
        <h5 className="mb-1">Dokumentansvarlige</h5>
        <UncontrolledTooltip placement="top" target="__ttdokumentansvarlige">
          Legg til flere brukere under "Prosjektmedlemmer"
        </UncontrolledTooltip>
        <p className="mr-2">
          <i id="__ttdokumentansvarlige" className="fa fa-info-circle fa-fw text-info" />
        </p>
      </div>
      <ViewEditTable
        gridOptions={{
          popupParent: document.body,
        }}
        data={_data}
        columns={_columns}
        onSave={saveChanges}
        newItemTemplateObject={{
          role: "",
          user: null,
        }}
        canEdit={true}
        canAddRows={false}
      />
    </div>
  );
};
