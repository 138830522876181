import React from "react";
import { Alert, Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { IChecklist } from "../../../../../../types/checklist.types";
import { usePublishDoksamChecklistMutation } from "../checklistRTK";
import { FailedAlert, MCLoader } from "../../../../components";

const PublishDoskamChecklistModal: React.FC<{ close: () => void; checklist: IChecklist }> = ({ close, checklist }) => {
  const [publishChecklistToDoksam, { isError, error, isLoading, isUninitialized, isSuccess }] =
    usePublishDoksamChecklistMutation();

  return (
    <Modal isOpen toggle={() => close()}>
      <ModalHeader toggle={() => close()}>Publiser til Doksam</ModalHeader>
      <ModalBody>
        {isUninitialized === true && <span>Ønsker du å publisere en ny revisjon av dokumenter til Doksam?</span>}
        {isError === true && <FailedAlert errorMessage={error} />}
        {isLoading && <MCLoader />}
        {isSuccess && (
          <Alert color="success">
            <i className="fa fa-check fa-fw" />
            Sjekkliste publisert til Doksam!
          </Alert>
        )}
      </ModalBody>
      {isUninitialized === true && (
        <ModalFooter>
          <Button onClick={close}>Avbryt</Button>
          <Button
            onClick={() =>
              publishChecklistToDoksam({
                checklistId: checklist._id,
                projectId: checklist.project,
              })
            }
            color="success"
          >
            Publiser
          </Button>
        </ModalFooter>
      )}
    </Modal>
  );
};

export default PublishDoskamChecklistModal;
