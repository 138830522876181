import React from "react";
import { IChecklist } from "../../../../../../types/checklist.types";
import { Button } from "reactstrap";
import PublishDoskamChecklistModal from "./PublishDoskamChecklist";

const DoksamChecklistBanner: React.FC<{ checklist: IChecklist }> = ({ checklist }) => {
  const [showModal, setShowModal] = React.useState<boolean>(false);

  return (
    <div className="d-flex p-2" style={{ borderRadius: "5px", backgroundColor: "#2f2f49" }}>
      {showModal && <PublishDoskamChecklistModal checklist={checklist} close={() => setShowModal(false)} />}
      <h4 className="text-white">
        <b>{checklist.doksam.docId}</b>
      </h4>
      <div className="flex-fill" />
      <Button onClick={() => setShowModal(true)} color="success">
        Publiser til Doksam
      </Button>
    </div>
  );
};

export default DoksamChecklistBanner;
