import { IAddChecklistBody, IPostBodyCheclistImportExcel } from "mc-shared/zod/checklistSchema";
import { IAllFilter } from "mc-shared/zod/commonSchema";
import { IChecklist, IChecklistParts, IChecklistPopulated } from "../../../../../types/checklist.types";
import { IId } from "../../../../../types/global.types";
import { apiSlice } from "../../../apiSlice";
import { setChecklistAllFiltersInRedux } from "./checklistSlice";

export interface IChecklistGetAllQueryInput {
  projectId: string;
  allFilters: IAllFilter[];
  freeTextSearch?: string;
  pns: string;
  doksamDocId?: string;
}

export interface IChecklistImportExcelHttp {
  params: {
    projectId: string;
  };
  body: IPostBodyCheclistImportExcel;
}

export const checklistRTK = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getProjectChecklists: builder.query<IChecklistPopulated[], IChecklistGetAllQueryInput>({
      query: (input) => {
        return {
          url: `/project/${input.projectId}/checklist/all`,
          method: "POST",
          body: {
            allFilters: input.allFilters,
            pns: input.pns,
            freeTextSearch: input.freeTextSearch,
            doksamDocId: input.doksamDocId,
          },
        };
      },
      providesTags: ["Checklist"],
    }),
    getChecklistMulticonsultTemplates: builder.query<IChecklistPopulated[], { projectId: string }>({
      query: (input) => {
        return {
          url: `/project/${input.projectId}/checklist/templates`,
          method: "GET",
        };
      },
      providesTags: ["Checklist"],
    }),
    getSingleChecklist: builder.query<IChecklistPopulated, { projectId: string; checklistId: string | number }>({
      query: (input) => {
        return {
          url: `/project/${input.projectId}/checklist/instance/${input.checklistId}`,
          method: "GET",
        };
      },
      providesTags: ["Checklist"],
    }),
    getUniqueChecklistTags: builder.query<IId[], { projectId: string }>({
      query: (input) => {
        return {
          url: `/project/${input.projectId}/checklist/tags`,
          method: "GET",
        };
      },
      providesTags: ["Checklist"],
    }),
    getActiveProjectChecklistsCreatedByLoggedInUser: builder.query<IChecklist[], { userId: string; projectId: string }>(
      {
        query: (input) => ({
          url: `/project/${input.projectId}/checklist/createdByUser/`,
          method: "GET",
        }),
        providesTags: ["Checklist"],
      },
    ),
    getChecklistFilters: builder.query<IAllFilter[], { projectId: string }>({
      query: (input) => ({
        url: `/project/${input.projectId}/checklist/filters/`,
        method: "GET",
      }),
      async onQueryStarted({ projectId, ...createChecklistParams }, { dispatch, queryFulfilled }) {
        const resp = await queryFulfilled;
        dispatch(setChecklistAllFiltersInRedux(resp.data));
      },
      providesTags: ["Checklist"],
    }),
    deleteChecklist: builder.mutation<IChecklist, { projectId: string; checklistId: string }>({
      query(input) {
        return {
          url: `/project/${input.projectId}/checklist/${input.checklistId}/`,
          method: "PUT",
        };
      },
      invalidatesTags: ["Checklist"],
    }),
    createChecklistFromImportedExcel: builder.mutation<IChecklist, IChecklistImportExcelHttp>({
      query(input) {
        return {
          url: `/project/${input.params.projectId}/checklist/import/excel`,
          method: "POST",
          body: input.body,
        };
      },
      invalidatesTags: ["Checklist"],
    }),
    updateChecklistIds: builder.mutation<
      IChecklistParts,
      {
        projectId: string;
        checklistId: string;
      }
    >({
      query(input) {
        return {
          url: `/project/${input.projectId}/checklist/${input.checklistId}/updatechecklistids`,
          method: "PUT",
        };
      },
      invalidatesTags: ["Checklist"],
    }),
    getChecklistsGeneratedFromTemplate: builder.query<IChecklist[], { projectId: string; templateId: number }>({
      query(input) {
        return {
          url: `/project/${input.projectId}/checklist/templates/checklists.from.template.get`,
          method: "POST",
          body: {
            templateId: input.templateId,
          },
        };
      },
      providesTags: ["Checklist"],
    }),
    createChecklist: builder.mutation<IChecklist, { projectId: string } & IAddChecklistBody>({
      query(input) {
        const { projectId, ...body } = input;
        return {
          url: `/project/${input.projectId}/checklist/add`,
          method: "POST",
          body,
        };
      },
      invalidatesTags: ["Checklist"],
    }),
    publishDoksamChecklist: builder.mutation<void, { checklistId: string; projectId: string }>({
      query: (input) => ({
        url: `/project/${input.projectId}/checklist/doksamchecklist/publish`,
        method: "POST",
        body: input,
      }),
    }),
  }),
});

export const {
  useGetProjectChecklistsQuery,
  useGetSingleChecklistQuery,
  useGetUniqueChecklistTagsQuery,
  useGetActiveProjectChecklistsCreatedByLoggedInUserQuery,
  useGetChecklistFiltersQuery,
  useDeleteChecklistMutation,
  useCreateChecklistFromImportedExcelMutation,
  useUpdateChecklistIdsMutation,
  useGetChecklistsGeneratedFromTemplateQuery,
  useCreateChecklistMutation,
  useGetChecklistMulticonsultTemplatesQuery,
  usePublishDoksamChecklistMutation,
} = checklistRTK;
