import React, { useMemo } from "react";
import { Alert, Button, ButtonGroup, Modal, ModalBody, ModalHeader } from "reactstrap";
import { IApp, IMinUser } from "../../../../types/global.types";
import { STANDARD_COLORS } from "../../app/globalConstants";
import { doSendUserMessage } from "../../app/routes/admin/restQueries";
import { MCApplicationsUrlEnum } from "../../frontendConstants";
import { setShowSuccessMessageThunk } from "../../global/globalSlice";
import { useAppDispatch } from "../../hooks";
import { ISpecificationTable } from "../../types";
import Avatar from "../Avatar";
import ContactForm from "../ContactForm/ContactForm";
import ExcelImportValidatorWithSpecTable from "../ExcelImportValidator/ExcelImportValidatorWithSpecTable";
import HierarchyTreeSelector from "../HierarchyTreeSelector/HierarchyTreeSelector";
import SearchInput from "../SearchInput/SearchInput";
import "./TemplateSelector.css";

export type TemplateSelectorType = "MC" | "PROJECT" | "INSTANCE" | "EXCEL";

export interface ITemplate {
  _id: string;
  name: string;
  templateType: TemplateSelectorType;
  category?: string | null;
  [key: string]: any;
  description?: string;
  admin?: IMinUser;
}

export interface IHierarchyCategory {
  _id: string;
  parentId: string;
  props: {
    templateId: string | null;
    title: string | null;
  };
}

const TemplateSelector: React.FC<{
  app: Pick<IApp, "icon" | "iconColor" | "name">;
  header: string;
  templates: ITemplate[];
  onCreate: (template: ITemplate) => void;
  createNewTemplate?: () => void;
  closeModal: () => void;
  isTemplateProject: boolean;
  isTitleEmpty?: boolean;
  descriptionAndAdmin?: boolean;
  showMCButton?: boolean;
  showProjectButton?: boolean;
  showInstanceButton?: boolean;
  preSelectedTemplate?: ITemplate;
  disableOpprettButton?: boolean;
  onTemplateSelected?: (template: ITemplate) => void;
  excelImport: {
    enabled: boolean;
    specificationTable?: ISpecificationTable;
    onCreateFromExcel?: (data: string[][]) => void;
    zodValidation?: any;
  };
  bottomDescriptionSection?: React.ReactNode;
}> = ({
  app,
  header,
  children,
  templates,
  onCreate,
  createNewTemplate,
  closeModal,
  isTemplateProject,
  isTitleEmpty,
  descriptionAndAdmin,
  showMCButton = true,
  showProjectButton = true,
  showInstanceButton = true,
  preSelectedTemplate,
  disableOpprettButton,
  onTemplateSelected,
  excelImport,
  bottomDescriptionSection,
}) => {
  const [filter, setFilter] = React.useState("");
  const [type, setType] = React.useState<TemplateSelectorType>("MC");
  const [selected, setSelected] = React.useState<ITemplate>(preSelectedTemplate);
  const [importedFormattedExcelDataItems, setImportedFormattedExcelDataItems] = React.useState<string[][]>(null);
  const [showContactForm, setShowContactForm] = React.useState<boolean>(false);

  const standardTemplateName = "Standard";
  const tomMalTemplateName = "Tom mal";

  const selectTemplate = (template: ITemplate) => {
    if (onTemplateSelected != null) {
      onTemplateSelected(template);
    }
    setSelected(template);
  };

  const dispatch = useAppDispatch();
  const filteredTemplates = useMemo(() => {
    let filtered = [];

    if (type === "MC") {
      filtered = templates.filter((template) => template.templateType === "MC");
    }
    if (type === "INSTANCE") {
      filtered = templates.filter((template) => template.templateType === "INSTANCE");
    }
    if (type === "PROJECT") {
      filtered = templates.filter((template) => template.templateType === "PROJECT");
    }

    if (filter !== "") {
      filtered = filtered.filter((template) => template.name.toLowerCase().includes(filter.toLowerCase()));
    }

    return filtered;
  }, [type, templates, filter]);

  let categories: string[] = [];

  if (type === "MC") {
    categories = [
      ...new Set(filteredTemplates.map((template) => template.category).filter((category) => category != null)),
    ];
  }

  const parentNode: IHierarchyCategory = {
    _id: "main-parent",
    parentId: null,
    props: {
      templateId: "Maler",
      title: null,
    },
  };

  const groupNodes: IHierarchyCategory[] = categories
    .map((category, index) => {
      return {
        _id: `category-placeholder-${index}`,
        parentId: parentNode._id,
        props: {
          templateId: category,
          title: null,
        },
      };
    })
    .sort((a, b) => {
      const aTitle = String(a.props.templateId || "").toLowerCase();
      const bTitle = String(b.props.templateId || "").toLowerCase();
      return aTitle.localeCompare(bTitle);
    });

  let templateNodes: IHierarchyCategory[] = filteredTemplates
    .map((template) => {
      if (template.category === null || template.category === "" || template.templateType === "INSTANCE") {
        return {
          _id: template._id,
          parentId: parentNode._id,
          props: {
            templateId: template.name,
            title: null,
          },
        };
      } else {
        const placeholder = groupNodes?.find((ph) => ph.props.templateId === template.category);
        return {
          _id: template._id,
          parentId: placeholder?._id,
          props: {
            templateId: null,
            title: template.name,
          },
        };
      }
    })
    .sort((a, b) => {
      if (a.props.templateId === standardTemplateName || a.props.templateId === tomMalTemplateName) return -1;

      if (a.props.templateId != null && b.props.templateId != null) {
        return a.props.templateId.localeCompare(b.props.templateId);
      }

      if (a.props.title != null && b.props.title != null) {
        return a.props.title.toLowerCase().localeCompare(b.props.title.toLowerCase());
      }
      return 0;
    });

  const templateTreeNodes = [parentNode, ...groupNodes, ...templateNodes];

  const sortedFinalTemplates = templateTreeNodes.sort((a, b) => {
    const aIsMainParentChild = a.parentId === "main-parent";
    const bIsMainParentChild = b.parentId === "main-parent";
    const aIsParent = templateTreeNodes.some((template) => template.parentId === a._id);
    const bIsParent = templateTreeNodes.some((template) => template.parentId === b._id);

    if (aIsMainParentChild && !aIsParent && (!bIsMainParentChild || bIsParent)) return -1;
    if (bIsMainParentChild && !bIsParent && (!aIsMainParentChild || aIsParent)) return 1;

    if (aIsParent && !bIsParent) return 1;
    if (!aIsParent && bIsParent) return -1;

    return 0;
  });

  const findTemplateById = (templateId: any, templates: any[]): ITemplate | undefined => {
    return templates.find((template) => template._id === templateId);
  };

  const onSubmit = () => {
    if (type === "EXCEL") {
      excelImport?.onCreateFromExcel(importedFormattedExcelDataItems);
    } else {
      onCreate(selected);
    }
  };

  const onTabChange = (tab: TemplateSelectorType) => {
    if (tab === "MC" && app.name === MCApplicationsUrlEnum.ISSUE) {
      selectTemplate(templates.find((template) => template.name === standardTemplateName));
    }
    if (tab === "MC" && app.name === MCApplicationsUrlEnum.CHECKLIST) {
      selectTemplate(templates.find((template) => template.name === tomMalTemplateName));
    }
    if (tab === "PROJECT") {
      selectTemplate(templates.find((template) => template.templateType === "PROJECT"));
    }
    if (tab === "INSTANCE") {
      selectTemplate(templates.find((template) => template.templateType === "INSTANCE"));
    }
    if (tab === "EXCEL") {
      selectTemplate(null);
    }
    setType(tab);
  };

  const isOpprettButtonDisabled =
    type === "EXCEL"
      ? importedFormattedExcelDataItems?.length === 0 || isTitleEmpty === true || disableOpprettButton === true
      : disableOpprettButton === true || selected == null;

  const send = async (message: string, subject: string) => {
    sendUserMessage(message, subject, () => setShowContactForm(false));
  };

  const sendUserMessage = async (message: string, subject: string, closeModal: () => void) => {
    try {
      await doSendUserMessage(subject, message);
      dispatch(setShowSuccessMessageThunk("Takk for tilbakemeldingen.\nVi vil komme tilbake til deg så snart vi kan."));
      closeModal();
    } catch (error) {
      dispatch(setShowSuccessMessageThunk("Noe gikk galt! Prøv å sende en e-post i stedet."));
    }
  };

  return (
    <>
      <Modal
        size={"md"}
        className={"template-selector d-flex"}
        isOpen={true}
        toggle={closeModal}
        style={{ height: "40rem", minHeight: "30rem", minWidth: "80rem", maxWidth: "80rem" }}
      >
        <ModalHeader toggle={closeModal}>{header}</ModalHeader>
        <div className="d-flex h-100 p-2 overflow-auto">
          <div className="d-flex flex-column overflow-hidden" style={{ resize: "horizontal", minWidth: "22rem" }}>
            <ButtonGroup className="w-100 mb-2">
              {showMCButton === true && (
                <Button
                  color={type === "MC" ? "primary" : "secondary"}
                  onClick={() => onTabChange("MC")}
                  active={type === "MC"}
                >
                  MC-maler
                </Button>
              )}
              {showProjectButton === true && (
                <Button
                  color={type === "PROJECT" ? "primary" : "secondary"}
                  onClick={() => onTabChange("PROJECT")}
                  active={type === "PROJECT"}
                >
                  Prosjektmaler
                </Button>
              )}
              {showInstanceButton === true && (
                <Button
                  color={type === "INSTANCE" ? "primary" : "secondary"}
                  onClick={() => onTabChange("INSTANCE")}
                  active={type === "INSTANCE"}
                >
                  Kopier fra eksisterende
                </Button>
              )}
              {excelImport.enabled === true && (
                <Button
                  color={type === "EXCEL" ? "primary" : "secondary"}
                  onClick={() => onTabChange("EXCEL")}
                  active={type === "EXCEL"}
                >
                  Excel
                </Button>
              )}
            </ButtonGroup>
            {type !== "EXCEL" && (
              <>
                <div className="mb-2">
                  <SearchInput placeholder="Filtrer maler" value={filter} onChange={(e) => setFilter(e.target.value)} />
                </div>
                <div className="d-flex flex-column h-100 overflow-auto" style={{ background: "#FBFBFB" }}>
                  <div className="d-flex flex-column h-100">
                    <div className="d-flex flex-grow-1 overflow-auto mb-1" style={{ height: "0px" }}>
                      <HierarchyTreeSelector
                        style={{
                          width: "352px",
                          maxWidth: "352px",
                          background: "#ededed",
                        }}
                        isVisible
                        nodeList={sortedFinalTemplates}
                        idProp={"templateId"}
                        titleProp={"title"}
                        selectedNodeIds={[selected?._id]}
                        barLabel="Maler"
                        hideLabelBar={true}
                        onSelectionChange={(node) => {
                          const selectedTemplate = findTemplateById(node[0]?.id, filteredTemplates);
                          selectTemplate(selectedTemplate);
                        }}
                      />
                    </div>
                    <div className="d-flex overflow-hidden">
                      <Button onClick={() => setShowContactForm(true)} color="mc-blue" className="mr-2">
                        <i className="fa fa-envelope fa-fw" />
                        Forslag til ny mal
                      </Button>
                    </div>
                  </div>
                </div>
              </>
            )}
            {type === "EXCEL" && excelImport?.specificationTable == null && (
              <>
                <Alert color="warning">Mangler spesifikasjonstabell for import</Alert>
              </>
            )}
            {type === "EXCEL" && importedFormattedExcelDataItems == null && excelImport?.specificationTable != null && (
              <ExcelImportValidatorWithSpecTable
                onValidated={(res) => setImportedFormattedExcelDataItems(res.sourceData as string[][])}
                validators={excelImport?.zodValidation}
                specificationTable={excelImport?.specificationTable}
                skipRows={1}
              />
            )}
            {type === "EXCEL" && importedFormattedExcelDataItems != null && (
              <Alert color="success">{importedFormattedExcelDataItems.length} rader importert fra excel</Alert>
            )}
          </div>
          {selected != null && descriptionAndAdmin === true && (type === "MC" || type === "PROJECT") && (
            <div className="d-flex flex-column w-50 ml-2 p-2" style={{ background: "#E4E4E4" }}>
              <h5 className="d-flex justify-content-center" style={{ textAlign: "center" }}>
                {selected.name}
              </h5>
              <div className="d-flex flex-column flex-grow-1 justify-content-end h-100 overflow-auto">
                {selected.name !== standardTemplateName && selected.name !== tomMalTemplateName && (
                  <div className="d-flex flex-column align-items-center my-2">
                    <div style={{ marginRight: "-.5em" }}>
                      <Avatar id={selected.admin?._id} height="50px" />
                    </div>
                    <h5 className="my-1">{selected.admin?.name}</h5>{" "}
                    <div
                      className="memberGroupBadge"
                      style={{ color: `${STANDARD_COLORS.orange}`, border: `3px solid ${STANDARD_COLORS.orange}` }}
                    >
                      ADMIN
                    </div>
                  </div>
                )}
                <div className="d-flex h-100 overflow-auto">
                  <p>{selected.description}</p>
                </div>
              </div>
              {bottomDescriptionSection != null && <div className="p-2">{bottomDescriptionSection}</div>}
            </div>
          )}
          <div className="d-flex flex-column w-100 ml-2" style={{ background: "#FBFBFB" }}>
            <div className="d-flex">{children}</div>
            <div className="d-flex flex-column flex-grow-1 justify-content-between mt-2">
              {isTemplateProject === false && (
                <>
                  {type !== "EXCEL" && (
                    <div className="grid-container pl-10 pr-10 pt-2">
                      {(type === "MC" || type === "PROJECT") && <b className="mb-0">Valgt mal</b>}
                      {type === "INSTANCE" && <b className="mb-0">Kopier fra</b>}
                      <p className="mb-0">{selected?.name}</p>
                    </div>
                  )}
                  <div className="d-flex w-100 justify-content-center align-items-end flex-grow-1">
                    <Button
                      disabled={isOpprettButtonDisabled}
                      size="md"
                      color="success"
                      style={{ height: "unset" }}
                      onClick={() => onSubmit()}
                    >
                      Opprett
                    </Button>
                  </div>
                </>
              )}
              {isTemplateProject === true && (
                <div className="d-flex w-100 justify-content-center align-items-end flex-grow-1">
                  <Button
                    disabled={isOpprettButtonDisabled}
                    size="md"
                    color="success"
                    style={{ height: "unset" }}
                    onClick={() => createNewTemplate()}
                  >
                    Opprett ny mal
                  </Button>
                </div>
              )}
            </div>
          </div>
        </div>
      </Modal>
      <Modal style={{ maxWidth: "60vw" }} isOpen={showContactForm} toggle={() => setShowContactForm(false)}>
        <ModalHeader toggle={() => setShowContactForm(false)}>Kontakt</ModalHeader>
        <ModalBody>
          <ContactForm sendMessage={send} prefilledSubject={"Forslag til ny mal:"} />
        </ModalBody>
      </Modal>
    </>
  );
};

export default TemplateSelector;
